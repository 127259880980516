import React from "react"
import { useHomeContext } from "../../context/landlord_context"

const BuildingCode = () => {
  const { home } = useHomeContext()
  const { buildingCodesCoverage } = home

  return (
    <>
      <h3>Building Code Coverage:</h3>
      <p>
        {`Building Codes coverage is an available endorsement that covers increased costs that may be required to comply with local building codes or ordinances. ${
          buildingCodesCoverage
            ? `You currently have ${buildingCodesCoverage} of your dwelling limit of Building Codes coverage. ${
                buildingCodesCoverage !== "20%" &&
                "If you would like to look at increasing this coverage, please either reply to this email or call us."
              }`
            : "You currently do not have this coverage on your policy. Please call us or reply to this email if you would like to add it."
        }`}
      </p>
      <br />
    </>
  )
}

export default BuildingCode
