import React from "react"
import { useHomeContext } from "../../context/landlord_context"

const Deductible = () => {
  const { home } = useHomeContext()
  const { deductible } = home

  return (
    <>
      <h3>Deductible:</h3>
      <p>
        {deductible === "$500/$1,000"
          ? "In the event of a covered loss, we will pay to restore you to the value of your property damaged at the time of loss minus your deductible. Your deductible is currently set at $500 for all perils and $1,000 for wind and hail damage."
          : deductible === "$500/$3,000"
          ? "In the event of a covered loss, we will pay to restore you to the value of your property damaged at the time of loss minus your deductible. Your deductible is currently set at $500 for all perils and $3,000 for wind and hail damage."
          : deductible === "$1,000"
          ? "In the event of a covered loss, we will pay to restore you to the value of your property damaged at the time of loss minus your deductible. Your deductible is currently set at $1,000."
          : deductible === "$1,000/$3,000"
          ? "In the event of a covered loss, we will pay to restore you to the value of your property damaged at the time of loss minus your deductible. Your deductible is currently set at $1,000 for all perils and $3,000 for wind and hail damage."
          : deductible === "$2,500"
          ? "In the event of a covered loss, we will pay to restore you to the value of your property damaged at the time of loss minus your deductible. Your deductible is currently set at $2,500."
          : deductible === "$5,000"
          ? "In the event of a covered loss, we will pay to restore you to the value of your property damaged at the time of loss minus your deductible. Your deductible is currently set at $5,000."
          : "In the event of a covered loss, we will pay to restore you to the value of your property damaged at the time of loss minus your deductible. Your deductible is currently set at $1,000."}
      </p>
      <br />
    </>
  )
}

export default Deductible
