import React from "react"
import { useHomeContext } from "../../context/landlord_context"

const Burglary = () => {
  const { home } = useHomeContext()
  const { burglary } = home

  return (
    <>
      <h3>Burglary:</h3>
      <p>
        {burglary === "Purchased"
          ? "In the event that there is a loss due to burglary on the premises, this coverage would help restore you to the value of what was burgled at the time of loss, minus your deductible. Please note, that your property cannot be vacant or unoccupied for more than a period of 90 days. You do have this coverage on your policy."
          : "In the event that there is a loss due to burglary on the premises, this coverage would help restore you to the value of what was burgled at the time of loss, minus your deductible. You currently do not have this coverage on your policy. If you would like to add it, please give us a call, or simply reply to this email."}
      </p>
      <br />
    </>
  )
}

export default Burglary
