import React from "react"
import { useHomeContext } from "../../context/landlord_context"
import { formatPrice } from "../../utils/helper"

const Additional = () => {
  const { home } = useHomeContext()
  const { additionalLivingExpense } = home

  return (
    <>
      <h3>Additional Living Expense:</h3>
      <p>{`The Additional Living Expense coverage takes care of any expenses incurred if your home becomes uninhabitable due to a covered loss. You currently have ${formatPrice(
        additionalLivingExpense
      )} of Additional Living Expense or up to 24 months of coverage.`}</p>
      <br />
    </>
  )
}

export default Additional
