import React from "react"
import { useHomeContext } from "../../context/landlord_context"
import { formatPrice } from "../../utils/helper"

const Medical = () => {
  const { home } = useHomeContext()
  const { premisesMedical } = home

  return (
    <>
      <h3>Premises Medical Protection:</h3>
      <p>{`In the event that someone is injured while on your property, we pay up to ${formatPrice(
        premisesMedical
      )} for their medical expenses.`}</p>
      <br />
    </>
  )
}

export default Medical
