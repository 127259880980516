import React from "react"
import { useHomeContext } from "../../context/landlord_context"
import { formatPrice } from "../../utils/helper"

const Dwelling = () => {
  const { home } = useHomeContext()
  const { dwelling } = home

  return (
    <>
      <h3>Dwelling Coverages:</h3>
      <p>
        {`Your home is currently covered for up to ${formatPrice(
          dwelling
        )}. In the event of a covered loss, we will pay up to this amount to either fix or replace your home minus your deductible. Due to market value increases, rising inflation, or a workforce shortage, this amount may not be enough to fully replace your home. If you feel that ${formatPrice(
          dwelling
        )} isn’t enough, please give us a call and we can look at increasing your coverage amount.`}
      </p>
      <br />
    </>
  )
}

export default Dwelling
