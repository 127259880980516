import React from "react"
import { useHomeContext } from "../../context/landlord_context"

// HOME FORM
const FormOne = () => {
  const { home, handleHomeChange, handleNextHomeStep } = useHomeContext()
  return (
    <form className="form" onSubmit={handleNextHomeStep}>
      <div className="form-center">
        <div className="row">
          <h2>Landlord</h2>

          {/* Number Input Section */}
          <div className="vehicle-container">
            <div className="field-container">
              {/* Dwelling */}
              <div className="dwelling-container">
                <label htmlFor="dwelling" className="label">
                  <span>Dwelling Coverage:</span>
                </label>
                <input
                  type="number"
                  name="dwelling"
                  value={home.dwelling}
                  onChange={handleHomeChange}
                  // required
                />
              </div>
              {/* Family Liability */}
              <div className="dwelling-container">
                <label htmlFor="familyLiability" className="label">
                  <span>Family Liability Protection:</span>
                </label>
                <input
                  type="number"
                  name="familyLiability"
                  value={home.familyLiability}
                  onChange={handleHomeChange}
                  // required
                />
              </div>
              {/* Guest Medical */}
              <div className="dwelling-container">
                <label htmlFor="premisesMedical" className="label">
                  <span>Premises Medical Protection:</span>
                </label>
                <input
                  type="number"
                  name="premisesMedical"
                  value={home.premisesMedical}
                  onChange={handleHomeChange}
                  // required
                />
              </div>
              {/* Personal Property */}
              <div className="dwelling-container">
                <label htmlFor="personalProperty" className="label">
                  <span>Personal Property Protection:</span>
                </label>
                <input
                  type="number"
                  name="personalProperty"
                  value={home.personalProperty}
                  onChange={handleHomeChange}
                  // required
                />
              </div>
              {/* Other Structures */}
              <div className="dwelling-container">
                <label htmlFor="otherStructures" className="label">
                  <span>Other Structures Protection:</span>
                </label>
                <input
                  type="number"
                  name="otherStructures"
                  value={home.otherStructures}
                  onChange={handleHomeChange}
                  // required
                />
              </div>
              {/* Additional Living Expense */}
              <div className="dwelling-container">
                <label htmlFor="additionalLivingExpense" className="label">
                  <span>Additional Living Expense:</span>
                </label>
                <input
                  type="number"
                  name="additionalLivingExpense"
                  value={home.additionalLivingExpense}
                  onChange={handleHomeChange}
                  // required
                />
              </div>
            </div>
          </div>
        </div>

        <hr className="line" />
        {/* next button */}
        <div className="btn-container">
          <button className="btn next-btn" type="submit">
            Next
          </button>
        </div>
      </div>
    </form>
  )
}

export default FormOne
