import { Link } from "gatsby"
import React from "react"
import { useGlobalContext } from "../../context/global_context"

const BackToTop = () => {
  const { scrollToTop } = useGlobalContext()

  return (
    <div>
      <div className="container">
        {/* back to top button */}
        <button className="backToTop-btn" onClick={scrollToTop}>
          Back To Top
        </button>
        <div className="seperator">|</div>
        {/* back to homepage button */}
        <Link to="/" className="backToTop-link">
          Back To Homepage
        </Link>
      </div>
    </div>
  )
}

export default BackToTop
