import React from "react"
import { useHomeContext } from "../../context/landlord_context"

const Vandalism = () => {
  const { home } = useHomeContext()
  const { vandalism } = home

  return (
    <>
      <h3>Vandalism:</h3>
      <p>
        {vandalism === "Purchased"
          ? "The vandalism coverage is an optional coverage that protects your property from vandalism or a loss resulting from fire caused by vandalism. You do currently have this coverage on your policy."
          : "The vandalism coverage is an optional coverage that protects your property from vandalism or a loss resulting from fire caused by vandalism. You currently do not have this coverage on your policy. If you would like to add it, please give us a call, or simply reply to this email."}
      </p>
      <br />
    </>
  )
}

export default Vandalism
