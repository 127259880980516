import React from "react"
import { useHomeContext } from "../../context/landlord_context"
import Dwelling from "./Dwelling"
import Liability from "./Liability"
import Medical from "./Medical"
import Property from "./Property"
import Other from "./Other"
import Additional from "./Additional"
import BuildingCode from "./BuildingCode"
import Deductible from "./Deductible"
import Burglary from "./Burglary"
import Vandalism from "./Vandalism"

// Document Component
const MyDocument = () => {
  const { home } = useHomeContext()
  const { windHailDeductible, allPerilDeductible } = home

  return (
    <>
      <div>
        <p>Dear [firstname],</p>
        <br />
        <p>
          Your landlord insurance policy is about to renew and this presents us
          with a good time to discuss any concerns or questions that you may
          have about your policy.
        </p>
        <br />
        <p>
          To ensure that you are aware of your coverages, we have put together
          the following review of your policy. If you would like to discuss any
          part of your policy, please give us a call at [agencyphone] or simply
          reply to this email.
        </p>
        <br />
        <h3>Coverage Explanations</h3>
        <br />

        {/* Dynamic Data */}
        <Dwelling />
        <Liability />
        <Medical />
        <Property />
        <Other />
        <Additional />
        <BuildingCode />
        <Deductible />
        <Burglary />
        <Vandalism />

        <h3>Additional Coverages:</h3>
        <p>Your home policy can cover other exposures such as:</p>
        <br />
        <p>
          home day care, golf carts, identity theft expenses, musical
          instruments, building materials theft, business property, jewelry,
          watches, furs, cameras, electronic data recovery, silverware theft,
          sports equipment and fire department charges.
        </p>
        <br />
        <p>
          If you have questions about any of the above optional coverages,
          please give us a call at [agencyphone].
        </p>
        <br />

        <h3>Other things to note:</h3>
        <p>
          If you have any questions about the coverages listed above, please do
          not hesitate to reach out to us either by replying to this email or
          calling at [agencyphone].
        </p>
        <br />
        <p>
          Thank you for being a member of our agency, we recognize that there
          are plenty of other options out there. We strive to give you the best
          service we can. If there is anything, we can help you with, please let
          us know.
        </p>
        <br />
        <p>Best Regards,</p>
        <br />
        <br />
        <br />
        <p>[signature]</p>
      </div>
    </>
  )
}

export default MyDocument
