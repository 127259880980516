export const formatPrice = number => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(number)
}
export const percentOfDwelling = (percent, dwelling) => {
  return Number(percent) * Number(dwelling)
}
